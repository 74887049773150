export const designs = [
    {
        id: '1',
        title: 'None',
        image: 'https://i.imgur.com/8tB6zVv.png',
        imageDark: 'https://i.imgur.com/3EtnBE0.png',
        hasNomPerso: false,
        hasNomPerso2: false,
        hasTitreRP: false,
        hasImage1: false,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '2',
        title: 'Basic',
        image: 'https://i.imgur.com/80fuP5e.png',
        imageDark: 'https://i.imgur.com/VJe5zhO.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: false,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '3',
        title: 'Framed',
        image: 'https://i.imgur.com/wj4ANqV.png',
        imageDark: 'https://i.imgur.com/p0G3BGS.png',
        hasNomPerso: false,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '4',
        title: 'Float',
        image: 'https://i.imgur.com/uHaz9L6.png',
        imageDark: 'https://i.imgur.com/5fOIcI7.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '5',
        title: 'Clean',
        image: 'https://i.imgur.com/czWJiax.png',
        imageDark: 'https://i.imgur.com/EpxpZzH.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '6',
        title: 'Saturn',
        image: 'https://i.imgur.com/14ssOaI.png',
        imageDark: 'https://i.imgur.com/eu6U20K.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '7',
        title: 'Float left',
        image: 'https://i.imgur.com/yG2LCaU.png',
        imageDark: 'https://i.imgur.com/nWYCbK2.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '8',
        title: 'Float right',
        image: 'https://i.imgur.com/R3ofQ6w.png',
        imageDark: 'https://i.imgur.com/VYadNK4.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '9',
        title: 'Jupiter',
        image: 'https://i.imgur.com/bARtfwN.png',
        imageDark: 'https://i.imgur.com/Az8ExxG.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '10',
        title: 'Diamond',
        image: 'https://i.imgur.com/AOrZuYh.png',
        imageDark: 'https://i.imgur.com/AOhjdFn.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '11',
        title: 'Ring',
        image: 'https://i.imgur.com/HxzTZXv.png',
        imageDark: 'https://i.imgur.com/jwCyW6s.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '12',
        title: 'Dublin',
        image: 'https://i.imgur.com/BssWt3K.png',
        imageDark: 'https://i.imgur.com/x3JzLbP.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '13',
        title: 'Trapeze',
        image: 'https://i.imgur.com/XCCcSoc.png',
        imageDark: 'https://i.imgur.com/eRf778e.png',
        hasNomPerso: true,
        hasNomPerso2: true,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: true,
    },
    {
        id: '14',
        title: 'Moon',
        image: 'https://i.imgur.com/4vjahnP.png',
        imageDark: 'https://i.imgur.com/vpC1LEA.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '15',
        title: 'Conversation',
        image: 'https://i.imgur.com/6h23a5O.png',
        imageDark: 'https://i.imgur.com/ZgN56IW.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: false,
        hasImage3: false,
    },
    {
        id: '16',
        title: 'Clean2',
        image: 'https://i.imgur.com/smMFupV.png',
        imageDark: 'https://i.imgur.com/5sEStyx.png',
        hasNomPerso: true,
        hasNomPerso2: true,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: true,
    },
    {
        id: '17',
        title: 'Chat',
        image: 'https://i.imgur.com/KUwntfY.png',
        imageDark: 'https://i.imgur.com/pQ2bpRW.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '18',
        title: 'Battle',
        image: 'https://i.imgur.com/7O8wX9v.png',
        imageDark: 'https://i.imgur.com/l8Aw7h4.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '19',
        title: 'Letter',
        image: 'https://i.imgur.com/ftvgLK8.png',
        imageDark: 'https://i.imgur.com/0Yi5G0N.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '20',
        title: 'Dash',
        image: 'https://i.imgur.com/WaLalyY.png',
        imageDark: 'https://i.imgur.com/5LMXngs.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '21',
        title: 'Lizzie',
        image: 'https://i.imgur.com/aKXjuES.png',
        imageDark: 'https://i.imgur.com/vVMet5g.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '22',
        title: 'Royal',
        image: 'https://i.imgur.com/DlHm2LZ.png',
        imageDark: 'https://i.imgur.com/BeF8MgP.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '23',
        title: 'Balance',
        image: 'https://i.imgur.com/YE56PJo.png',
        imageDark: 'https://i.imgur.com/ceP0Gi3.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
    {
        id: '24',
        title: 'Vows',
        image: 'https://i.imgur.com/0pHFWMc.png',
        imageDark: 'https://i.imgur.com/MCg72uK.png',
        hasNomPerso: true,
        hasNomPerso2: false,
        hasTitreRP: true,
        hasImage1: true,
        hasImage2: true,
        hasImage3: false,
    },
]
