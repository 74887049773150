import { useState, useEffect } from 'react'

export default function Filter({ filters, setFilters, value }) {
    const [isChecked, setIsChecked] = useState(true)
    useEffect(() => {
        setIsChecked(filters.includes(value))
    }, [filters, value])

    const handleFilter = (event) => {
        let newFilters
        if (event.target.checked) {
            newFilters = [...filters, event.target.value]
            setIsChecked(true)
        } else {
            newFilters =
                filters.filter((item) => item !== event.target.value) || []
            setIsChecked(false)
        }
        setFilters(newFilters)
    }

    return (
        <div className="flex items-center my-1">
            <input
                className="mr-3 w-5 h-5 status-filter"
                type="checkbox"
                value={value}
                checked={isChecked}
                onChange={handleFilter}
            />
            <label htmlFor={value}>{value}</label>
        </div>
    )
}
