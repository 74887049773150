import React from 'react'
import { useState, useEffect } from 'react'
import GraphLight from '../../../Assets/graph-light.svg'
import GraphDark from '../../../Assets/graph-dark.svg'

import Confirmation from '../../../Components/Confirmation/Confirmation'
import {
    getRPs,
    getCharacters,
    deleteRP,
    deleteToOpenRP,
} from '../../../utils/utils'
import InfoMessage from '../../../Components/InfoMessage/InfoMessage'
import MenuItem from '../../../Components/MenuItem/MenuItem'
import Spinner from '../../../Components/Spinner/Spinner'

import { useTheme } from '../../../Context/theme'
import { lightThemes } from '../../../Data/const'

import RPOngoing from '../../../Components/RPOngoing/RPOngoing'
import RPFinished from '../../../Components/RPFinished/RPFinished'
import RPToOpen from '../../../Components/RPToOpen/RPToOpen'

import {
    countRPPerStatus,
    countRPPerCharacter,
    countRPPerForum,
} from '../../../utils/utils'

export default function Tracker({ setModal, modal }) {
    const [allOpenRPs, setAllOpenRPs] = useState([])
    const [allClosedRPs, setAllClosedRPs] = useState([])
    const [allToOpenRPs, setAllToOpenRPs] = useState([])
    const [filteredCount, setFilteredCount] = useState()
    const [countByStatus, setCountByStatus] = useState([])
    const [countByManualStatus, setCountByManualStatus] = useState([])
    const [upToDateTotal, setUpToDateTotal] = useState(0)
    const [notUpToDateTotal, setNotUpToDateTotal] = useState(0)
    const [characterData, setCharacterData] = useState([])
    const [forumData, setForumData] = useState([])
    const [finishedFilteredCount, setFinishedFilteredCount] = useState()
    const [characters, setCharacters] = useState([])
    const [charactersLoaded, setCharactersLoaded] = useState()
    const [statusFilters, setStatusFilters] = useState(['À jour', 'Pas à jour'])
    const [characterFilters, setCharacterFilters] = useState([])
    const [finishedCharacterFilters, setFinishedCharacterFilters] = useState([])
    const [isLoading, setIsLoading] = useState()
    const [hasError, setHasError] = useState(false)
    const [rpToUpdate, setRpToUpdate] = useState('')
    const [modalType, setModalType] = useState('add')
    const [activeTab, setActiveTab] = useState('RPs en cours')

    const { theme } = useTheme()

    useEffect(() => {
        const { upToDate, notUpToDate } = countRPPerStatus([
            ...countByStatus,
            ...countByManualStatus,
        ])
        setUpToDateTotal(upToDate)
        setNotUpToDateTotal(notUpToDate)
    }, [countByStatus, countByManualStatus])

    const fetchRPs = async () => {
        setIsLoading(true)
        setCharacterData([])
        setForumData([])
        try {
            const { data } = await getRPs()
            setCharacterData(countRPPerCharacter(data?.trackedRPs))
            setForumData(countRPPerForum(data?.trackedRPs))
            const trackedRPs = data?.trackedRPs
            const formattedTrackedRPs = trackedRPs.map((item) => ({
                ...item,
                status: item.manualTrackingStatus,
            }))
            const toOpenRPs = data?.toOpenRPs
            setAllToOpenRPs(toOpenRPs)
            const newOpenRPs = Array.from(formattedTrackedRPs).filter(
                (item) => !item.isClosed
            )
            setAllOpenRPs(newOpenRPs)
            const newClosedRPs = Array.from(formattedTrackedRPs).filter(
                (item) => item.isClosed
            )
            setAllClosedRPs(newClosedRPs)
            const newManualRPs = Array.from(formattedTrackedRPs).filter(
                (item) => item.manualTracking && !item.isClosed
            )
            setCountByManualStatus(newManualRPs)
        } catch (_) {
            setAllOpenRPs([])
            setCharacterData([])
            setForumData([])
            triggerError()
        } finally {
            setIsLoading(false)
        }
    }

    const fetchCharacters = async () => {
        try {
            const { data } = await getCharacters()
            setCharacters(data)
            setCharacterFilters(data)
            setFinishedCharacterFilters(data)
        } catch (_) {
            setCharacters([])
            triggerError()
        } finally {
            setCharactersLoaded(true)
        }
    }

    const openAddModal = () => {
        setModalType('add')
        setModal(true)
    }

    const openUpdateModal = (rpLink) => {
        setModalType('update')
        setModal(true)
        setRpToUpdate(rpLink)
    }

    const openStatsModal = () => {
        setModalType('stats')
        setModal(true)
    }

    const closeModal = () => {
        setModal(false)
    }

    const triggerError = () => {
        setHasError(true)
    }

    const removeRP = async (rpLink) => {
        try {
            await deleteRP(rpLink)
            fetchRPs()
        } catch (_) {
            triggerError()
        }
    }

    const removeToOpenRP = async (rpName) => {
        try {
            await deleteToOpenRP(rpName)
            fetchRPs()
        } catch (_) {
            triggerError()
        }
    }

    useEffect(() => {
        fetchRPs()
        fetchCharacters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const typeData = [
        ['Type', 'Count'],
        ['RPs en cours', allOpenRPs?.length],
        ['RPs terminés', allClosedRPs?.length],
        ['RPs à ouvrir', allToOpenRPs?.length],
    ]

    const statusData = [
        ['Status', 'Count'],
        ['RPs à jour', upToDateTotal],
        ['RPs pas à jour', notUpToDateTotal],
        [
            'En chargement',
            allOpenRPs?.length - (upToDateTotal + notUpToDateTotal),
        ],
    ]

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <h1 className="sm:ml-4">Mes RPs</h1>
                <button
                    onClick={openAddModal}
                    className="w-40 py-2 border border-secondary bg-secondary text-secondary-text rounded-md">
                    Ajouter un RP
                </button>
            </div>
            <section className="sm:mr-8 w-full mb-4 sm:flex sm:items-center">
                <ul className="sm:flex">
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="tracker-category"
                        name="RPs en cours">
                        RPs en cours{' '}
                        <span className="text-xs ml-0.5">
                            (
                            {activeTab === 'RPs en cours'
                                ? filteredCount
                                : allOpenRPs?.length}
                            )
                        </span>
                    </MenuItem>
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="tracker-category"
                        name="RPs terminés">
                        RPs terminés{' '}
                        <span className="text-xs ml-0.5">
                            (
                            {activeTab === 'RPs terminés'
                                ? finishedFilteredCount
                                : allClosedRPs?.length}
                            )
                        </span>
                    </MenuItem>
                    <MenuItem
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        testId="tracker-category"
                        name="RPs à ouvrir">
                        RPs à ouvrir{' '}
                        <span className="text-xs ml-0.5">
                            ({allToOpenRPs?.length})
                        </span>
                    </MenuItem>
                </ul>
                {!isLoading && allOpenRPs?.length > 0 && (
                    <img
                        onClick={openStatsModal}
                        className="w-6 h-6 ml-4 mt-1 cursor-pointer graph-icon"
                        src={
                            lightThemes.includes(theme) ? GraphLight : GraphDark
                        }
                        alt="statistiques"
                    />
                )}
            </section>
            {hasError && charactersLoaded && !isLoading && (
                <InfoMessage type="warning">
                    <p>Une erreur est survenue, veuillez ré-essayer.</p>
                </InfoMessage>
            )}
            {(isLoading || !charactersLoaded) && <Spinner centered margin />}
            {!hasError && !isLoading && charactersLoaded ? (
                activeTab === 'RPs en cours' ? (
                    <RPOngoing
                        fetchRPs={fetchRPs}
                        removeRP={removeRP}
                        allRPs={allOpenRPs}
                        characters={characters}
                        charactersLoaded={charactersLoaded}
                        openUpdateModal={openUpdateModal}
                        setRPList={setAllOpenRPs}
                        RPList={allOpenRPs}
                        setStatusFilters={setStatusFilters}
                        statusFilters={statusFilters}
                        setCharacterFilters={setCharacterFilters}
                        characterFilters={characterFilters}
                        theme={theme}
                        filteredCount={filteredCount}
                        setFilteredCount={setFilteredCount}
                        setCountByStatus={setCountByStatus}
                        countByManualStatus={countByManualStatus}
                        // setCountByManualStatus={setCountByManualStatus}
                    />
                ) : activeTab === 'RPs terminés' ? (
                    <RPFinished
                        fetchRPs={fetchRPs}
                        removeRP={removeRP}
                        characters={characters}
                        allRPs={allClosedRPs}
                        theme={theme}
                        openUpdateModal={openUpdateModal}
                        finishedCharacterFilters={finishedCharacterFilters}
                        setFinishedCharacterFilters={
                            setFinishedCharacterFilters
                        }
                        setFinishedFilteredCount={setFinishedFilteredCount}
                    />
                ) : (
                    <RPToOpen allRPs={allToOpenRPs} removeRP={removeToOpenRP} />
                )
            ) : null}

            {modal && (
                <Confirmation
                    closeModal={closeModal}
                    fetchRPs={fetchRPs}
                    source={modalType}
                    characters={characters}
                    rpToUpdate={rpToUpdate}
                    typeData={typeData}
                    statusData={statusData}
                    characterData={characterData}
                    forumData={forumData}
                />
            )}
        </>
    )
}
